<form [ngClass]="cssClass">
    <div class="dropdown-container">
        <span class="input-label text-suzy-grey-800">{{ label }}</span>
        <igx-simple-combo #simpleCombo [formControl]="control" [data]="sortedMissions"
            (selectionChanging)="onMissionSelected($event)"
            displayKey="search_internal_text" valueKey="mission_id" density="compact" [placeholder]="placeholder"
            class="mission-search-combo" searchPlaceholder="Search">
            <igx-prefix><igx-icon>search</igx-icon></igx-prefix>
            <ng-template igxComboItem let-display let-key="valueKey">
                <action-dropdown-item [item]="display"></action-dropdown-item>
            </ng-template>
            <ng-template igxComboEmpty>
                <ng-container *ngIf="searching | async; else elseEmpty">
                    <div class="loading">
                        <igx-circular-bar [animate]="false" [indeterminate]="true"
                            [textVisibility]="false"></igx-circular-bar>
                    </div>
                </ng-container>
                <ng-template #elseEmpty>
                    <span>There are no items to display</span>
                </ng-template>
            </ng-template>
        </igx-simple-combo>
    </div>
</form>