/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import {
  ActionKind,
  ActionStructureMultipleChoiceKind,
  BrandClusterKind,
  MissionCleanRequestStatus,
  MissionKind,
  MissionLiveStatus,
} from '@asksuzy/typescript-sdk';
import { TranslateService } from '@ngx-translate/core';
import {
  BehaviorSubject,
  catchError,
  map,
  Observable,
  of,
  shareReplay,
  Subject,
  switchMap,
} from 'rxjs';
import { IAction } from '../models/suzy/IAction';
import { IDPair } from '../models/suzy/IDPair';
import { IMission } from '../models/suzy/IMission';
import { Markdown2HtmlPipe } from '../pipes/markdown2html.pipe';
import { StripHtmlPipe } from '../pipes/strip-html.pipe';
import { PipingTokenService } from './piping-token.service';
import { SuzySDK } from './suzy-sdk';

enum MissionStatus {
  creating = 0,
  approving = 1,
  active = 2,
  complete = 4,
  pre_launch = 5,
  paused = 6
}

@Injectable()
export class SuzyDataService {
  missions = new BehaviorSubject<any[]>([]);
  missions$ = this.missions.asObservable();

  constructor(
    private suzySDK: SuzySDK,
    private translate: TranslateService,
    private pipingTokenService: PipingTokenService,
    private stripHtmlPipe: StripHtmlPipe,
    private markdown2Html: Markdown2HtmlPipe
  ) {}

  private brandProfileQuestionsNetworkCache: Array<{
    brand_id: string;
    mission_id: string;
    sub: Subject<IDPair[]>;
    run: any;
    complete: any;
  }> = [];
  private externalAttributesNetworkCache: Array<{
    brand_id: string;
    mission_id: string;
    sub: Subject<IDPair[]>;
    run: any;
    complete: any;
  }> = [];
  private panelsCached = false;
  private panelsCache: Array<IDPair> = [];
  private segmentsCached = false;
  private segmentsCache: Array<IDPair> = [];
  private brandFoldersCache: Array<IDPair> = [];
  private globalProfileQuestionsCache: Array<{
    brand_id: string;
    mission_id: string;
    items: Array<any>;
  }> = [];
  private globalProfileAnswersCache: Array<{
    brand_id: string;
    brand_profile_question_id: string;
    items: Array<any>;
  }> = [];
  public readonly standardAgeClassificationId =
    '1e57e775-86ae-46ff-b788-a39318c2dbf2';
  public readonly standardRegionClassificationId =
    'a1948c52-8c57-4aec-bbde-758d9c4a6974';

  clearPanelsAndSegmentsCache(): void {
    this.panelsCached = false;
    this.panelsCache = [];
    this.segmentsCached = false;
    this.segmentsCache = [];
  }

  clearBrandProfileQuestionsCache(): void {
    this.globalProfileQuestionsCache = [];
    this.globalProfileAnswersCache = [];
    this.brandProfileQuestionsNetworkCache.forEach((item) => {
      if (item.sub) {
        item.sub.next([]);
        item.sub.complete();
      }
    });
    this.brandProfileQuestionsNetworkCache = [];
    this.externalAttributesNetworkCache.forEach((item) => {
      if (item.sub) {
        item.sub.next([]);
        item.sub.complete();
      }
    });
    this.externalAttributesNetworkCache = [];
  }

  clearBrandFoldersCache(): void {
    this.brandFoldersCache = [];
  }

  setBrandFoldersCache(items: IDPair[]): void {
    this.brandFoldersCache = items;
  }

  loadPanelsAndSegments(brand_id: string): Observable<boolean> {
    return new Observable<boolean>((observable) => {
      this.suzySDK.BrandClusterGroup.getActiveByBrandAsync(
        brand_id,
        0,
        400,
        '',
        'cluster_group_name.sort',
        false
      ).subscribe({
        next: (data: any) => {
          this.panelsCache = data.items
            .filter((x) => x.cluster_kind === BrandClusterKind.panel)
            .map((x: any) => ({
              id: x.brand_cluster_group_id,
              name: x.cluster_group_name,
            }));
          this.panelsCached = true;
          this.segmentsCache = data.items
            .filter((x) => x.cluster_kind === BrandClusterKind.standard)
            .map((x: any) => ({
              id: x.brand_cluster_group_id,
              name: x.cluster_group_name,
            }));
          this.segmentsCached = true;
          observable.next(true);
        },
        error: (error: any) => {
          observable.error(error);
        },
      });
    });
  }

  audiencePanelsGet(brand_id: string): Observable<Array<IDPair>> {
    if (this.panelsCached) {
      return of(this.panelsCache);
    }
    return new Observable<Array<IDPair>>((observable) => {
      this.suzySDK.BrandClusterGroup.getActiveByBrandAsync(
        brand_id,
        0,
        400,
        '',
        'cluster_group_name.sort',
        false,
        BrandClusterKind.panel
      ).subscribe({
        next: (data: any) => {
          this.panelsCache = data.items.map((x: any) => ({
            id: x.brand_cluster_group_id,
            name: x.cluster_group_name,
          }));
          this.panelsCached = true;
          observable.next(this.panelsCache);
        },
        error: (error: any) => {
          observable.error(error);
        },
      });
    });
  }

  audienceSegmentsGet(brand_id: string): Observable<Array<IDPair>> {
    if (this.segmentsCached) {
      return of(this.segmentsCache);
    }
    return new Observable<Array<IDPair>>((observable) => {
      this.suzySDK.BrandClusterGroup.getActiveByBrandAsync(
        brand_id,
        0,
        400,
        '',
        'cluster_group_name.sort',
        false,
        BrandClusterKind.standard
      ).subscribe({
        next: (data: any) => {
          this.segmentsCache = data.items.map((x: any) => ({
            id: x.brand_cluster_group_id,
            name: x.cluster_group_name,
          }));
          this.segmentsCached = true;
          observable.next(this.segmentsCache);
        },
        error: (error: any) => {
          observable.error(error);
        },
      });
    });
  }

  brandClusterGroupGetValues(
    brand_id: string,
    brand_cluster_group_id: string
  ): Observable<Array<IDPair>> {
    return new Observable<Array<IDPair>>((observable) => {
      this.suzySDK.BrandCluster.getBrandClusterByClusterGroup({
        brand_id: brand_id,
        brand_cluster_group_id: brand_cluster_group_id,
        take: 400,
        skip: 0,
        order_by: 'cluster_name.sort',
      }).subscribe({
        next: (data: any) => {
          const mapped = data.items.map((x: any) => ({
            id: x.brand_cluster_id,
            name: x.cluster_name,
          }));
          observable.next(mapped);
        },
        error: (error: any) => {
          observable.error(error);
        },
      });
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ethnicityGetValues(brand_id: string): Observable<Array<IDPair>> {
    return new Observable<Array<IDPair>>((observable) => {
      this.suzySDK.Ethnicity.find({
        skip: 0,
        take: 400,
        order_by: 'ethnicity_name',
      }).subscribe({
        next: (data: any) => {
          const mapped = data.items.map((x: any) => ({
            id: x.ethnicity_id,
            name: x.ethnicity_name,
          }));
          observable.next(mapped);
        },
      });
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  stateGetValues(brand_id: string): Observable<Array<IDPair>> {
    return new Observable<Array<IDPair>>((observable) => {
      this.suzySDK.State.find({
        skip: 0,
        take: 400,
        order_by: 'state_long',
      }).subscribe({
        next: (data: any) => {
          const mapped = data.items.map((x: any) => ({
            id: x.state_id,
            name: x.state_long,
          }));
          observable.next(mapped);
        },
        error: (error: any) => {
          observable.error(error);
        },
      });
    });
  }

  ageGroupGetValues(ageClassificationId?: string): Observable<Array<IDPair>> {
    return new Observable<Array<IDPair>>((observable) => {
      this.suzySDK.AgeGroup.find({
        skip: 0,
        take: 50,
        age_classification_id:
          ageClassificationId ?? this.standardAgeClassificationId,
        order_by: 'age_group_name',
      }).subscribe({
        next: (data: any) => {
          const mapped = data.items.map((x: any) => ({
            id: x.age_group_id,
            name: x.age_group_name,
          }));
          observable.next(mapped);
        },
        error: (error: any) => {
          observable.error(error);
        },
      });
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  relationshipGetValues(brand_id: string): Observable<Array<IDPair>> {
    return new Observable<Array<IDPair>>((observable) => {
      this.suzySDK.Relationship.find({
        skip: 0,
        take: 400,
        order_by: 'relationship_name',
      }).subscribe({
        next: (data: any) => {
          const mapped = data.items.map((x: any) => ({
            id: x.relationship_id,
            name: x.relationship_name,
          }));
          observable.next(mapped);
        },
        error: (error: any) => {
          observable.error(error);
        },
      });
    });
  }

  educationLevelGetValues(): Observable<Array<IDPair>> {
    return new Observable<Array<IDPair>>((observable) => {
      this.suzySDK.Education.find({
        skip: 0,
        take: 400,
        order_by: 'education_name',
      }).subscribe({
        next: (data: any) => {
          const mapped = data.items.map((x: any) => ({
            id: x.education_id,
            name: x.education_name,
          }));
          observable.next(mapped);
        },
        error: (error: any) => {
          observable.error(error);
        },
      });
    });
  }

  employmentStatusGetValues(): Observable<Array<IDPair>> {
    return new Observable<Array<IDPair>>((observable) => {
      this.suzySDK.Employment.find({
        skip: 0,
        take: 400,
        order_by: 'employment_name',
      }).subscribe({
        next: (data: any) => {
          const mapped = data.items.map((x: any) => ({
            id: x.employment_id,
            name: x.employment_name,
          }));
          observable.next(mapped);
        },
        error: (error: any) => {
          observable.error(error);
        },
      });
    });
  }

  householdSizeGetValues(): Observable<Array<IDPair>> {
    return new Observable<Array<IDPair>>((observable) => {
      this.suzySDK.Household.find({
        skip: 0,
        take: 400,
        order_by: 'household_name',
      }).subscribe({
        next: (data: any) => {
          const mapped = data.items.map((x: any) => ({
            id: x.household_id,
            name: x.household_name,
          }));
          observable.next(mapped);
        },
        error: (error: any) => {
          observable.error(error);
        },
      });
    });
  }

  incomeLevelGetValues(): Observable<Array<IDPair>> {
    return new Observable<Array<IDPair>>((observable) => {
      this.suzySDK.IncomeLevel.find({
        skip: 0,
        take: 400,
        order_by: 'income_level_name',
      }).subscribe({
        next: (data: any) => {
          const mapped = data.items.map((x: any) => ({
            id: x.income_level_id,
            name: x.income_level_name,
          }));
          observable.next(mapped);
        },
        error: (error: any) => {
          observable.error(error);
        },
      });
    });
  }

  parentingStatusGetValues(): Observable<Array<IDPair>> {
    return new Observable<Array<IDPair>>((observable) => {
      this.suzySDK.Parenting.find({
        skip: 0,
        take: 400,
        order_by: 'parenting_name',
      }).subscribe({
        next: (data: any) => {
          const mapped = data.items.map((x: any) => ({
            id: x.parenting_id,
            name: x.parenting_name,
          }));
          observable.next(mapped);
        },
        error: (error: any) => {
          observable.error(error);
        },
      });
    });
  }

  regionGetValues(regionClassificationId?: string): Observable<Array<IDPair>> {
    return new Observable<Array<IDPair>>((observable) => {
      this.suzySDK.Region.find({
        skip: 0,
        take: 400,
        region_classification_id:
          regionClassificationId ?? this.standardRegionClassificationId,
        order_by: 'region_name',
      }).subscribe({
        next: (data: any) => {
          const mapped = data.items.map((x: any) => ({
            id: x.region_id,
            name: x.region_name,
          }));
          observable.next(mapped);
        },
        error: (error: any) => {
          observable.error(error);
        },
      });
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  brand(brand_id: string, keyword?: string): Observable<Array<IDPair>> {
    return new Observable<Array<IDPair>>((observable) => {
      this.suzySDK.Mission.getForBrand({
        brand_id: brand_id,
        mission_status: [
          MissionStatus.complete.toString(),
          MissionStatus.active.toString(),
        ].join(','),
        take: 400,
      }).subscribe({
        next: (data: any) => {
          const mapped = data.items.map((x: any) => ({
            id: x.mission_id,
            name: x.search_text,
          }));
          observable.next(mapped);
        },
        error: (error: any) => {
          observable.error(error);
        },
      });
    });
  }

  brandGetPropertyValue(
    brand_id: string,
    property_name: string
  ): Observable<string> {
    return new Observable<string>((observable) => {
      this.suzySDK.Brand.getBrand(brand_id).subscribe({
        next: (data: any) => {
          if (data.item && data.item.property_values) {
            observable.next(data.item.property_values[property_name]);
          } else {
            observable.next(undefined);
          }
        },
        error: (error: any) => {
          observable.error(error);
        },
      });
    });
  }

  missionsFindForCrosstab(
    brand_id: string,
    global: boolean,
    keyword?: string,
    take: number = 400
  ): Observable<Array<IMission>> {
    return new Observable<Array<IMission>>((observable) => {
      if (global) {
        this.missionsFind(
          brand_id,
          true,
          keyword,
          take,
          'stamp_utc',
          true
        ).subscribe({
          next: (data) => {
            observable.next(data);
          },
          error: (error) => {
            observable.error(error);
          },
        });
      } else {
        this.suzySDK.Mission.getForCrossTab({
          brand_id: brand_id,
          only_launched: true,
          take: take,
          keyword: keyword,
          order_by: 'stamp_utc',
          descending: true,
        }).subscribe({
          next: (data: any) => {
            observable.next(data.item.missions.items);
          },
          error: (error: any) => {
            observable.error(error);
          },
        });
      }
    });
  }

  missionsFind(
    brand_id: string,
    global: boolean,
    keyword?: string,
    take: number = 400,
    order_by: string = '',
    descending: boolean = false
  ): Observable<Array<IMission>> {
    return new Observable<Array<IMission>>((observable) => {
      let mission_kind = '';
      let mission_status = [
        MissionStatus.complete.toString(),
        MissionStatus.active.toString(),
      ].join(',');
      if (global) {
        mission_kind = MissionKind.external_cint.toString();
        mission_status = '';
      }
      this.suzySDK.Mission.getForBrand({
        brand_id: brand_id,
        mission_kind: mission_kind,
        mission_status: mission_status,
        take: take,
        keyword: keyword,
        order_by: order_by,
        descending: descending,
      }).subscribe({
        next: (data: any) => {
          if (global) {
            const prefix =
              '[' + this.translate.instant('builder.global') + '] ';
            for (const mission of data.items) {
              mission.search_text = prefix + mission.search_text;
            }
          }
          observable.next(data.items);
        },
        error: (error: any) => {
          observable.error(error);
        },
      });
    });
  }

  missionsGet(brand_id: string, mission_id: string): Observable<IMission> {
    
    return this.suzySDK.Mission.getMission({ brand_id, mission_id }).pipe(
      map((data: any) => {
        return data.item || [];
      }),
      catchError((error) => {
        return of([]); 
      })
    );
  }

  actionsGetForMission(
    brand_id: string,
    mission_id: string
  ): Observable<Array<IAction>> {
    return new Observable<Array<IAction>>((observable) => {
      this.suzySDK.Action.getActionsForMission({
        brand_id,
        mission_id,
      }).subscribe({
        next: (data: any) => {
          if (data.items.length == 0) {
            // could be transparent mission, if so, two api calls, if not, only one
            this.suzySDK.Mission.getMission({ brand_id, mission_id }).subscribe(
              {
                next: (data: any) => {
                  const items = [] as Array<any>;
                  if (data?.item?.first_action) {
                    items.push(data.item.first_action);
                  }
                  observable.next(items);
                },
                error: (error: any) => {
                  observable.error(error);
                },
              }
            );
          } else {
            observable.next(data.items);
          }
        },
        error: (error: any) => {
          observable.error(error);
        },
      });
    });
  }

  exportToPPT(
    brand_id: string,
    action_id: string,
    mission_id: string,
    request_json: any,
    percentage: boolean,
    table_name?: string
  ): Observable<any> {
    return this.suzySDK.DataExplorer.queueDataExplorerPPTWithParameters(
      brand_id,
      {
        action_id,
        mission_id,
        request_json,
        percentage,
        table_name,
      }
    ).pipe(
      catchError((err) => {
        return of(err);
      })
    );
  }

  exportSurveyToPPT(brand_id: string, reportRequest: any): Observable<any> {
    return this.suzySDK.DataExplorer.queueSurveyDataExplorerPPTWithParameters(
      brand_id,
      reportRequest
    ).pipe(
      catchError((err) => {
        return of(err);
      })
    );
  }

  exportToExcel(
    brand_id: string,
    action_id: string,
    mission_id: string,
    request_json: any,
    percentage: boolean,
    table_name?: string
  ): Observable<any> {
    return this.suzySDK.DataExplorer.queueDataExplorerExcel(brand_id, {
      action_id,
      mission_id,
      request_json,
      percentage,
      table_name,
    }).pipe(
      catchError((err) => {
        return of(err);
      })
    );
  }

  exportSurveyToExcel(brand_id: string, reportRequest: any): Observable<any> {
    return this.suzySDK.DataExplorer.queueSurveyDataExplorerExcel(
      brand_id,
      reportRequest
    ).pipe(
      catchError((err) => {
        return of(err);
      })
    );
  }

  getAction(actionId: string, brandId: string): Observable<IAction> {
    return this.suzySDK.Action.getAction({
      brand_id: brandId,
      action_id: actionId,
    }).pipe(
      catchError(() => {
        return of(null);
      }),
      map((resp: any) => {
        if (resp?.success && resp?.item) {
          return resp.item as IAction;
        }
        return null;
      })
    );
  }

  public getActionsForMission(
    missionId: string,
    brandId: string,
    missionKind?: MissionKind
  ): Observable<Array<any>> {
    return this.suzySDK.Action.getActionsForMission({
      brand_id: brandId,
      mission_id: missionId,
    }).pipe(
      switchMap((data: any) => {
        if (!data?.items?.length) {
          return this.suzySDK.Mission.getMission({
            brand_id: brandId,
            mission_id: missionId,
          }).pipe(
            map((resp: any) => {
              const items = [] as Array<any>;
              if (resp?.item?.first_action) {
                items.push(resp.item.first_action);
              }
              return items;
            })
          );
        }
        return of(data.items);
      }),
      map((items: any[]) => {
        const allActions = items;
        const hasMaxdiffActions = allActions.some(
          (x) => x.action_kind === ActionKind.maxdiff
        );
        const actions = !hasMaxdiffActions
          ? items
          : items.filter((x) => !x.parent_step_id);

        return actions.map((item) => {
          item.question_number = this.getQuestionNumberForAction(
            item,
            allActions
          );
          item.search_text = this.processMissionText(
            item,
            missionKind === MissionKind.standard
              ? undefined
              : item.question_number
          );
          return item;
        });
      })
    );
  }

  public getActionsForExternalLinkMission(
    missionId: string,
    brandId: string
  ): Observable<Array<any>> {
    return this.suzySDK.Step.getPrimaryForMission({
      brand_id: brandId,
      mission_id: missionId,
      skip: 0,
      take: 100,
    }).pipe(
      map((data: any) => {
        const items = data?.items ?? [];
        const allActions = items.map((x) => x.first_action);
        const legalConsentCount = (
          allActions.filter((x) => x.is_legal_consent) ?? []
        ).length;
        const actions = allActions.filter((x) => !x.is_legal_consent);

        return actions.map((item) => {
          item.question_number = this.getQuestionNumberForAction(
            item,
            allActions,
            legalConsentCount * -1
          );
          item.search_text = this.processMissionText(
            item,
            item.question_number
          );
          return item;
        });
      })
    );
  }

  public getQuestionNumberForAction(
    action: any,
    allActions: any[],
    offset = 0
  ): string {
    let priority = action.step_priority;
    const actions = allActions.map((x) => {
      return x.first_action ?? x;
    });

    const hasMaxdiffActions = actions.some(
      (x) => x.action_kind === ActionKind.maxdiff
    );
    if (!hasMaxdiffActions) {
      return `Q${priority + offset}. `;
    }

    const actionIx = allActions.findIndex(
      (x) => x.action_id === action.action_id
    );
    const actionsBeforeSelf = actions.slice(0, actionIx) ?? [];
    const filteredActions =
      actionsBeforeSelf.filter(
        (x) => x.parent_step_id && x.action_kind === ActionKind.auto_assign
      ) ?? [];

    priority -= filteredActions.length;

    if (action.maxdiff?.chained_action_count > 1) {
      const { chained_action_count } = action.maxdiff;
      return `Q${priority}-Q${priority + (chained_action_count - 1)}. `;
    }
    return `Q${priority}. `;
  }

  public processMissionText(
    mission: any,
    questionNumber?: string
  ): BehaviorSubject<string> {
    if (mission.search_text && typeof mission.search_text === 'string') {
      mission.unStrippedSearchText = mission.search_text;
      mission.originalSearchText = mission.search_text;
    }
    let searchText =
      mission.search_text?.getValue !== undefined
        ? mission.search_text.getValue()
        : mission.search_text;
    const searchText$ = new BehaviorSubject<string>(searchText);

    if (!mission.originalSearchText) {
      if (!mission.question) {
        searchText = '';
      } else if (typeof mission.question === 'string') {
        searchText = mission.question;
      } else {
        searchText =
          this.getActionByKind(mission.action.first_action)?.question || '';
      }

      mission.unStrippedSearchText = searchText.replace(/\s+/g, ' ').trim();
      mission.originalSearchText = searchText;
    }
    mission.unStrippedSearchText = `${questionNumber ?? ''}${
      mission.unStrippedSearchText
    }`;
    mission.strippedSearchText = this.stripHtmlPipe.transform(
      mission.unStrippedSearchText.replace(PipingTokenService.Pattern, '{{Q}}'),
      { plain: true }
    );
    searchText$.next(
      this.markdown2Html.transform(
        mission.unStrippedSearchText.replace(
          PipingTokenService.Pattern,
          '{{Q}}'
        ),
        {
          secure: true,
        }
      )
    );

    let pipeData = mission.pipe_route || mission.pipeRoute;
    if (!pipeData) {
      pipeData = this.getActionByKind(mission.first_action || mission)?.piping;
      if (pipeData?.length) {
        pipeData = pipeData[0].pipe_route;
      }
    }
    if (pipeData?.pipe_location === 'question_text') {
      this.pipingTokenService
        .getLabel$(
          mission.brand_id,
          pipeData.source_mission_id,
          pipeData.source_action_id
        )
        .pipe(
          map((label) => {
            mission.unStrippedSearchText = mission.originalSearchText.replace(
              PipingTokenService.Pattern,
              `{{${label}}}`
            );
            mission.unStrippedSearchText = `${questionNumber ?? ''}${
              mission.unStrippedSearchText
            }`;
            mission.strippedSearchText = this.stripHtmlPipe.transform(
              mission.unStrippedSearchText,
              { plain: true }
            );
            return this.markdown2Html.transform(mission.unStrippedSearchText, {
              secure: true,
            });
          }),
          shareReplay(1)
        )
        .subscribe((text) => {
          searchText$.next(text);
        });
    }

    return searchText$;
  }

  private getActionByKind(action): any {
    switch (action?.action_kind) {
      case ActionKind.openended:
        return action.open_ended;
      case ActionKind.openended_video:
        return action.openended_video;
      case ActionKind.multiplechoice:
        return action.multiple_choice;
      case ActionKind.photoacquisition:
        return action.photo;
      case ActionKind.grid:
      case ActionKind.gridcustom:
      case ActionKind.gridrankscale:
        return action.grid;
      case ActionKind.sharetext:
        return action.share_text;
      case ActionKind.sharephoto:
        return action.share_photo;
      case ActionKind.sharelink:
        return action.share_link;
      case ActionKind.address:
        return action.address;
      case ActionKind.accept:
        return action.accept;
      case ActionKind.auto_assign:
        return action.auto_assign;
      case ActionKind.maxdiff:
        return action.maxdiff;
      case ActionKind.turf:
        return action.turf;
      default:
        break;
    }
  }

  public getAnswerChoicesForAction(
    brandId: string,
    actionId: string
  ): Observable<Array<IDPair>> {
    return new Observable<Array<IDPair>>((observable) => {
      this.suzySDK.Action.getAction({
        brand_id: brandId,
        action_id: actionId,
      }).subscribe({
        next: (data: any) => {
          if (data?.success && data?.item) {
            const mapped: Array<IDPair> = [];
            const action = data.item as IAction;
            const { action_kind, multiple_choice, auto_assign, grid, turf } =
              action;
            switch (action_kind) {
              case ActionKind.multiplechoice:
                if (
                  action.multiple_choice?.multiple_choice_kind ===
                  ActionStructureMultipleChoiceKind.standard
                ) {
                  (multiple_choice?.options ?? []).forEach((option) => {
                    mapped.push({
                      id: option.answer_id,
                      name: option.answer_text,
                    });
                  });
                } else if (
                  action.multiple_choice?.multiple_choice_kind ===
                  ActionStructureMultipleChoiceKind.rating
                ) {
                  (multiple_choice?.options ?? []).forEach((option) => {
                    mapped.push({
                      id: option.answer_id,
                      name: `${option.answer_text}${
                        option.ui_text ? ' - ' + option.ui_text : ''
                      }`,
                    });
                  });
                }
                break;
              case ActionKind.auto_assign:
                (auto_assign?.options ?? []).forEach((option) => {
                  mapped.push({
                    id: option.answer_id,
                    name: option.answer_text,
                  });
                });
                break;
              case ActionKind.gridrankscale:
              case ActionKind.gridcustom:
              case ActionKind.grid:
                (grid?.rows ?? []).forEach((option) => {
                  mapped.push({
                    id: option.answer_id,
                    name: option.answer_text,
                  });
                });
                break;
              case ActionKind.turf:
                (turf?.options ?? []).forEach((option) => {
                  mapped.push({
                    id: option.answer_id,
                    name: option.answer_text,
                  });
                });
                break;
              default:
                break;
            }

            observable.next(mapped);
          } else {
            observable.error(data?.message ?? 'Some error has happened!');
          }
        },
        error: (error: any) => {
          observable.error(error);
        },
      });
    });
  }

  public getPrimarySelectionForAction(
    brandId: string,
    actionId: string
  ): Observable<Array<IDPair>> {
    return new Observable<Array<IDPair>>((observable) => {
      this.suzySDK.Action.getAction({
        brand_id: brandId,
        action_id: actionId,
      }).subscribe({
        next: (data: any) => {
          if (data?.success && data?.item) {
            const mapped: Array<IDPair> = [];
            const action = data.item as IAction;
            const { action_kind, grid } = action;
            switch (action_kind) {
              case ActionKind.gridrankscale:
              case ActionKind.gridcustom:
              case ActionKind.grid:
                (grid?.columns ?? []).forEach((option) => {
                  mapped.push({
                    id: option.answer_id,
                    name: `${option.answer_text}${
                      option.ui_text ? ' - ' + option.ui_text : ''
                    }`,
                  });
                });
                break;
              default:
                break;
            }

            observable.next(mapped);
          } else {
            observable.error(data?.message ?? 'Some error has happened!');
          }
        },
        error: (error: any) => {
          observable.error(error);
        },
      });
    });
  }

  public getClusterKindByClusterGroupId(
    id: string
  ): BrandClusterKind | undefined {
    if (this.panelsCache.findIndex((x) => x.id === id) >= 0) {
      return BrandClusterKind.panel;
    }
    if (this.segmentsCache.findIndex((x) => x.id === id) >= 0) {
      return BrandClusterKind.standard;
    }

    return undefined;
  }

  public getBrandProfileQuestions(
    brand_id: string,
    mission_id: string
  ): Observable<IDPair[]> {
    return new Observable<IDPair[]>((observer) => {
      const cachedItems = this.globalProfileQuestionsCache.find(
        (x) => x.brand_id === brand_id && x.mission_id === mission_id
      );
      if (cachedItems?.items.length) {
        observer.next(cachedItems.items);
        return;
      }

      const cachedSub$ = this.brandProfileQuestionsNetworkCache.find(
        (x) => x.brand_id === brand_id && x.mission_id === mission_id
      );

      if (cachedSub$?.sub) {
        cachedSub$.sub.subscribe({
          next: (data) => {
            observer.next(data);
          },
          error: (error) => {
            observer.error(error);
          },
        });
        return;
      }

      const networkCall = {
        brand_id,
        mission_id,
        sub: new Subject<IDPair[]>(),
        run: () => {
          this.suzySDK.BrandProfileQuestion.getBrandProfileQuestionsByMission({
            brand_id,
            mission_id,
          }).subscribe({
            next: (data) => {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const items = (data?.items as Array<any>) ?? [];
              const options = items.map((x) => ({
                id: x.brand_profile_question_id,
                name: x.action?.multiple_choice?.question,
              }));
              this.globalProfileQuestionsCache.push({
                brand_id,
                mission_id,
                items: options,
              });
              observer.next(options);
              networkCall.complete(options);
            },
            error: (error) => {
              observer.error(error);
              networkCall.complete();
            },
          });
        },
        complete: (items?: IDPair[]) => {
          networkCall.sub.next(items ?? []);
          networkCall.sub.complete();
          this.brandProfileQuestionsNetworkCache =
            this.brandProfileQuestionsNetworkCache.filter(
              (x) =>
                !(
                  x.brand_id === networkCall.brand_id &&
                  x.mission_id === networkCall.mission_id
                )
            );
        },
      };
      this.brandProfileQuestionsNetworkCache.push(networkCall);
      networkCall.run();
    });
  }

  public getBrandProfileQuestionValues(
    brand_id: string,
    brand_profile_question_id: string,
    is_external_link_mission: boolean,
    mission_id?: string
  ): Observable<IDPair[]> {
    return new Observable<Array<IDPair>>((observer) => {
      const cachedItems = this.globalProfileAnswersCache.find(
        (x) =>
          x.brand_id === brand_id &&
          x.brand_profile_question_id === brand_profile_question_id
      );
      if (cachedItems?.items.length) {
        observer.next(cachedItems.items);
        return;
      }

      const loader$ = is_external_link_mission
        ? this.suzySDK.BrandProfileQuestion.getValuesByAttribute({
            brand_id,
            brand_profile_question_id,
            mission_id: mission_id ?? '',
          })
        : this.suzySDK.BrandProfileQuestion.getAttribute({
            brand_id,
            brand_profile_question_id,
          }).pipe(
            map((response: any) => {
              return {
                items: response?.item?.value_settings ?? [],
                success: response?.success ?? false,
              };
            })
          );

      loader$.subscribe({
        next: (data) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const answers = (data?.items ?? []).filter((x) => !x.is_hidden);
          const options = (answers ?? []).map((x) => ({
            id: x.answer_id,
            name: x.answer_text,
          }));
          this.globalProfileAnswersCache.push({
            brand_id,
            brand_profile_question_id,
            items: options,
          });

          observer.next(options);
        },
        error: (error) => {
          observer.error(error);
        },
      });
    });
  }

  public getPublicLinkAttributes(
    brand_id: string,
    mission_id: string
  ): Observable<IDPair[]> {
    return new Observable<IDPair[]>((observer) => {
      const cachedItems = this.globalProfileQuestionsCache.find(
        (x) => x.brand_id === brand_id && x.mission_id === mission_id
      );
      if (cachedItems?.items.length) {
        observer.next(cachedItems.items);
        return;
      }

      const cachedSub$ = this.externalAttributesNetworkCache.find(
        (x) => x.brand_id === brand_id && x.mission_id === mission_id
      );
      if (cachedSub$?.sub) {
        cachedSub$.sub.subscribe({
          next: (data) => {
            observer.next(data);
          },
          error: (error) => {
            observer.error(error);
          },
        });
        return;
      }

      const networkCall = {
        brand_id,
        mission_id,
        sub: new Subject<IDPair[]>(),
        run: () => {
          this.suzySDK.BrandProfileQuestion.getAvailableAttributesByMission({
            brand_id,
            mission_id,
          }).subscribe({
            next: (data) => {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const items = (data?.items as Array<any>) ?? [];
              const visibleItems = items.filter(
                (x) => x.external_system === 'XL' && !x.is_hidden
              );
              const options = visibleItems.map((x) => ({
                id: x.brand_profile_question_id,
                name: x.question_text,
              }));
              this.globalProfileQuestionsCache.push({
                brand_id,
                mission_id,
                items: options,
              });
              observer.next(options);
              networkCall.complete(options);
            },
            error: (error) => {
              observer.error(error);
              networkCall.complete();
            },
          });
        },
        complete: (items?: IDPair[]) => {
          networkCall.sub.next(items ?? []);
          networkCall.sub.complete();
          this.externalAttributesNetworkCache =
            this.externalAttributesNetworkCache.filter(
              (x) =>
                !(
                  x.brand_id === networkCall.brand_id &&
                  x.mission_id === networkCall.mission_id
                )
            );
        },
      };
      this.externalAttributesNetworkCache.push(networkCall);
      networkCall.run();
    });
  }

  public getResponseCountForMission(mission?: IMission): number {
    if (!mission?.mission_id) {
      return 0;
    }

    const { response_count, first_action } = mission;

    return first_action?.response_count ?? response_count ?? 0;
  }

  public checkIfMissionHasCleanedResponses(
    mission: IMission
  ): Observable<boolean> {
    const isGlobal = mission.mission_kind === MissionKind.external_cint;
    const details$ = !isGlobal
      ? this.suzySDK.MissionCleanRequest.getByMissionAsync(
          mission.brand_id,
          mission.mission_id
        )
      : this.suzySDK.CintQuality.getGroupedForMissionAsync(
          mission.brand_id,
          mission.mission_id
        );

    return new Observable<boolean>((observable) => {
      details$.subscribe({
        next: (data: any) => {
          if (!data?.success) {
            observable.next(false);
            return;
          }

          const hasCleanedResponses = (data?.items ?? []).some((x: any) => {
            const status = isGlobal
              ? x.cint_quality_report_status
              : x.mission_clean_request_status;
            return status === MissionCleanRequestStatus.completed;
          });

          observable.next(hasCleanedResponses);
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        error: (error: any) => {
          observable.next(false);
        },
      });
    });
  }

  public loadBrandFolders(brandId: string): Observable<IDPair[]> {
    let skip = 0;
    const take = 100;
    const searchParams = {
      take,
      brand_id: brandId,
      skip,
      descending: false,
    };
    const brandFolderNamesDict: Array<IDPair> = [];

    const processResponse = (resp: any): Observable<IDPair[]> => {
      if (!resp?.success || !resp?.items?.length) {
        return of(brandFolderNamesDict);
      }

      resp.items.forEach((x: any) => {
        const { brand_folder_id, folder_name } = x;
        brandFolderNamesDict.push({
          id: brand_folder_id,
          name: folder_name,
        });
      });

      if (!resp?.stepping?.more) {
        return of(brandFolderNamesDict);
      }

      skip = skip + take;
      searchParams.skip = skip;
      return loadData(searchParams);
    };

    const loadData = (searchParams: any): Observable<IDPair[]> => {
      return this.suzySDK.BrandFolder.getForBrand(searchParams).pipe(
        switchMap((resp: any) => {
          return processResponse(resp);
        })
      );
    };

    return loadData(searchParams);
  }

  public getBrandFolderName(folderId: string): Observable<string> {
    const name =
      this.brandFoldersCache.find((x) => x.id === folderId)?.name ?? '';
    return of(name);
  }

  public searchMissions(
    searchTerm: string,
    brandId: string
  ): Observable<any[]> {
    const take = !searchTerm ? 20 : 50;
    const missionStatus = [
      MissionStatus.active.toString(),
      MissionStatus.complete.toString(),
      MissionStatus.pre_launch.toString(),
      MissionStatus.paused.toString(),
    ].join(',');
    const globalMissionStatus = [
      MissionLiveStatus.active.toString(),
      MissionLiveStatus.paused.toString(),
      MissionLiveStatus.halted.toString(),
      MissionLiveStatus.complete.toString(),
      MissionLiveStatus.closed.toString(),
      MissionLiveStatus.can_complete.toString(),
    ].join(',');

    return this.suzySDK.Mission.getForBrand({
      brand_id: brandId,
      mission_status: missionStatus,
      global_mission_status_list: globalMissionStatus,
      keyword: searchTerm,
      take,
    }).pipe(
      map(
        (resp: {
          items: any[];
          paging: any;
          stepping: any;
          success: boolean;
        }) => {
          let missions = [];
          const items: any = resp.items
            .filter((mission) => {
              return (
                mission.mission_kind !== MissionKind.focusgroup &&
                mission.first_action?.action_kind !==
                  ActionKind.openended_video &&
                mission.first_action?.action_kind !==
                  ActionKind.photoacquisition
              );
            })
            .sort((a, b) => {
              const aText =
                typeof a.search_text === 'string' ? a.search_text : '';
              const bText =
                typeof b.search_text === 'string' ? b.search_text : '';
              return aText.localeCompare(bText);
            })
            .map((mission) => {
              if (mission.mission_kind === MissionKind.external_cint) {
                mission.search_text = `${mission.search_text}`;
              }
              mission.search_text = this.processMissionText(mission);
              mission.brand_tags_text = (mission.brand_tags ?? [])
                .map((tag) => tag.name)
                .join(', ');
              return mission;
            });

          missions = missions.concat(items);
          if (!searchTerm) {
            missions.sort((a: any, b: any) => {
              if (a.stamp_utc < b.stamp_utc) {
                return 1;
              }
              if (a.stamp_utc > b.stamp_utc) {
                return -1;
              }
              return 0;
            });
            missions = missions.slice(0, 20);
          }
          return missions;
        }
      )
    );
  }

  public getMissionActions(
    brandId: string,
    missionId: string
  ): Observable<Array<any>> {
    return this.suzySDK.Action.getActionsForMission({
      brand_id: brandId,
      mission_id: missionId,
    }).pipe(
      switchMap((data: any) => {
        if (!data?.items?.length) {
          return this.suzySDK.Mission.getMission({
            brand_id: brandId,
            mission_id: missionId,
          }).pipe(
            map((resp: any) => {
              const items = [] as Array<any>;
              if (resp?.item?.first_action) {
                items.push(resp.item.first_action);
              }
              return items;
            })
          );
        }
        return of(data.items);
      }),
      map((items: any[]) => {
        const allActions = items;
        const hasMaxdiffActions = allActions.some(
          (x) => x.action_kind === ActionKind.maxdiff
        );
        const actions = !hasMaxdiffActions
          ? items
          : items.filter((x) => !x.parent_step_id);

        return actions.map((item) => {
          const question_number = this.getQuestionNumberForAction(
            item,
            allActions
          );
          item.search_text = this.processMissionText(item);
          item.question_number = question_number.replace('. ', '');
          return item;
        });
      })
    );
  }

  public getMission(brandId: string, missionId: string): Observable<any> {
    return this.suzySDK.Mission.getMission({
      brand_id: brandId,
      mission_id: missionId,
    });
  }
}
